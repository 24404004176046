const _temp0 = require("./droplet.svg");
const _temp1 = require("./location-1.svg");
const _temp2 = require("./sunrise.svg");
const _temp3 = require("./sunrise2.svg");
const _temp4 = require("./sunrise4.svg");
const _temp5 = require("./sunrise5.svg");
const _temp6 = require("./sunset.svg");
const _temp7 = require("./sunset1.svg");
const _temp8 = require("./sunset2.svg");
const _temp9 = require("./sunset3.svg");
const _temp10 = require("./sunset4.svg");
const _temp11 = require("./sunset5.svg");
const _temp12 = require("./wind.svg");
module.exports = {
  "droplet": _temp0,
  "location-1": _temp1,
  "sunrise": _temp2,
  "sunrise2": _temp3,
  "sunrise4": _temp4,
  "sunrise5": _temp5,
  "sunset": _temp6,
  "sunset1": _temp7,
  "sunset2": _temp8,
  "sunset3": _temp9,
  "sunset4": _temp10,
  "sunset5": _temp11,
  "wind": _temp12
}