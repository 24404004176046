const _temp0 = require("./Blizzard-Night.svg");
const _temp1 = require("./Blizzard.svg");
const _temp2 = require("./Breezy-Snow.svg");
const _temp3 = require("./Breezy.svg");
const _temp4 = require("./Clear-Night.svg");
const _temp5 = require("./Drizzle-Night.svg");
const _temp6 = require("./Drizzle.svg");
const _temp7 = require("./Dust.svg");
const _temp8 = require("./Fog-Night.svg");
const _temp9 = require("./Fog.svg");
const _temp10 = require("./Hail-Night.svg");
const _temp11 = require("./Hail.svg");
const _temp12 = require("./Haze.svg");
const _temp13 = require("./Heavy-Rain-Night.svg");
const _temp14 = require("./Heavy-Rain.svg");
const _temp15 = require("./Mix-Rainfall-Night.svg");
const _temp16 = require("./Mix-Rainfall.svg");
const _temp17 = require("./Mostly-Cloudy-Night.svg");
const _temp18 = require("./Mostly-Cloudy.svg");
const _temp19 = require("./Mostly-Sunny.svg");
const _temp20 = require("./Partly-Cloudy-Night.svg");
const _temp21 = require("./Partly-Cloudy.svg");
const _temp22 = require("./Rain-Night.svg");
const _temp23 = require("./Rain.svg");
const _temp24 = require("./Scattered-Showers-Night.svg");
const _temp25 = require("./Scattered-Showers.svg");
const _temp26 = require("./Scattered-Thunderstorm-Night.svg");
const _temp27 = require("./Scattered-Thunderstorm.svg");
const _temp28 = require("./Severe-Thunderstorm-Night.svg");
const _temp29 = require("./Severe-Thunderstorm.svg");
const _temp30 = require("./Sleet-Night.svg");
const _temp31 = require("./Sleet.svg");
const _temp32 = require("./Smoke.svg");
const _temp33 = require("./Snow-Night.svg");
const _temp34 = require("./Snow.svg");
const _temp35 = require("./Tornado.svg");
const _temp36 = require("./weather-icon.svg");
const _temp37 = require("./weather-icons-01.svg");
module.exports = {
  "Blizzard-Night": _temp0,
  "Blizzard": _temp1,
  "Breezy-Snow": _temp2,
  "Breezy": _temp3,
  "Clear-Night": _temp4,
  "Drizzle-Night": _temp5,
  "Drizzle": _temp6,
  "Dust": _temp7,
  "Fog-Night": _temp8,
  "Fog": _temp9,
  "Hail-Night": _temp10,
  "Hail": _temp11,
  "Haze": _temp12,
  "Heavy-Rain-Night": _temp13,
  "Heavy-Rain": _temp14,
  "Mix-Rainfall-Night": _temp15,
  "Mix-Rainfall": _temp16,
  "Mostly-Cloudy-Night": _temp17,
  "Mostly-Cloudy": _temp18,
  "Mostly-Sunny": _temp19,
  "Partly-Cloudy-Night": _temp20,
  "Partly-Cloudy": _temp21,
  "Rain-Night": _temp22,
  "Rain": _temp23,
  "Scattered-Showers-Night": _temp24,
  "Scattered-Showers": _temp25,
  "Scattered-Thunderstorm-Night": _temp26,
  "Scattered-Thunderstorm": _temp27,
  "Severe-Thunderstorm-Night": _temp28,
  "Severe-Thunderstorm": _temp29,
  "Sleet-Night": _temp30,
  "Sleet": _temp31,
  "Smoke": _temp32,
  "Snow-Night": _temp33,
  "Snow": _temp34,
  "Tornado": _temp35,
  "weather-icon": _temp36,
  "weather-icons-01": _temp37
}